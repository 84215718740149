import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../utils/theme';
import { INTERNAL_LINKS } from '../utils/links';

const BannerContainer = styled.div`
  height: 60px;
  top: 0;
  width: 100%;
  background: linear-gradient(
    -60deg,
    ${THEME_COLORS.brandPink},
    ${THEME_COLORS.orangeSaturated},
    ${THEME_COLORS.brandPink},
    ${THEME_COLORS.blueSaturated}
  );
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    height: 50px;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const AlignmentContainer = styled.div`
  max-width: 500px;
  color: white;
  margin: auto;
`;

const TextContainer = styled.div`
  width: min-content;
  font-size: 160%;
  font-weight: 700;
  font-family: 'Catv 6x12 9', sans-serif;
  overflow: hidden;
  text-align: center;
  border-right: 2px solid hsl(0, 0%, 100%);
  border-right-color: transparent;
  white-space: nowrap;
  animation: typewriter 2s steps(23) 1s 1 normal both, blinkTextCursor 800ms infinite;
  @keyframes typewriter {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @keyframes blinkTextCursor {
    from {
      border-right-color: transparent;
    }
    to {
      border-right-color: hsl(0, 0%, 100%);
    }
  }
`;

export const Banner = () => {
  return (
    <BannerContainer>
      <AlignmentContainer>
        <TextContainer>
          <a style={{ color: 'white' }} target="_self" rel="noreferrer noopener" href={INTERNAL_LINKS.JOIN}>
            LEARN HOW TO JOIN DUBHACKS 2025
          </a>
        </TextContainer>
      </AlignmentContainer>
    </BannerContainer>
  );
};
