import React, { useCallback, useState, useEffect } from 'react';
import { EmblaOptionsType } from 'embla-carousel'; // https://www.embla-carousel.com/examples/predefined/
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { EmblaCarouselType } from 'embla-carousel';
import styled from 'styled-components';

import { THEME_COLORS } from '../../utils/theme';

import tp1 from '../../assets/slideshowpics/teampic1.jpeg';
import tp2 from '../../assets/slideshowpics/teampic2.jpeg';
import tp3 from '../../assets/slideshowpics/teampic3.jpeg';
import tp4 from '../../assets/slideshowpics/teampic4.jpeg';
import tp5 from '../../assets/slideshowpics/teampic5.jpeg';
import tp6 from '../../assets/slideshowpics/teampic6.jpeg';
import tp7 from '../../assets/slideshowpics/teampic7.jpeg';
import tp8 from '../../assets/slideshowpics/teampic8.jpeg';
import tp9 from '../../assets/slideshowpics/teampic9.jpeg';
import tp10 from '../../assets/slideshowpics/teampic10.jpeg';
import tp11 from '../../assets/slideshowpics/teampic11.jpeg';
import tp12 from '../../assets/slideshowpics/teampic12.jpeg';
import tp13 from '../../assets/slideshowpics/teampic13.jpeg';
import tp14 from '../../assets/slideshowpics/teampic14.jpg';
import tp15 from '../../assets/slideshowpics/teampic15.jpeg';
import tp16 from '../../assets/slideshowpics/teampic16.jpeg';
import tp17 from '../../assets/slideshowpics/teampic17.jpeg';
import tp18 from '../../assets/slideshowpics/teampic18.jpeg';
import tp19 from '../../assets/slideshowpics/teampic19.jpeg';
import tp20 from '../../assets/slideshowpics/teampic20.jpeg';
import tp21 from '../../assets/slideshowpics/teampic21.jpeg';
import tp22 from '../../assets/slideshowpics/teampic22.jpeg';
import tp23 from '../../assets/slideshowpics/teampic23.jpeg';

const Image = styled.img`
  border-radius: 5px;
  height: 400px;
  background: white;

  @media (max-width: 600px) {
    height: 300px;
  }
`;

const Embla = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  height: 100%;
  margin: 20px 5px 10px 5px;
  --slide-height: fit-content;
  --slide-spacing: 30px;
  --slide-size: fit-content;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    ${THEME_COLORS.brandPink}30,
    ${THEME_COLORS.orangeSaturated}30,
    ${THEME_COLORS.brandPink}30,
    ${THEME_COLORS.blueSaturated}30
  );
  background: white;
  @media (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    --slide-spacing: 10px;
  }
`;

const EmblaViewport = styled.div`
  overflow: hidden;
`;

const EmblaContainer = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
`;

const EmblaSlide = styled.div`
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
`;

const EmblaSlideNumber = styled.div`
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
`;

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
  onButtonClick?: (emblaApi: EmblaCarouselType) => void,
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
    if (onButtonClick) onButtonClick(emblaApi);
  }, [emblaApi, onButtonClick]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
    if (onButtonClick) onButtonClick(emblaApi);
  }, [emblaApi, onButtonClick]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = {
  slides: number[];
  options?: EmblaOptionsType;
};

const images = [
  tp16,
  tp9,
  tp4,
  tp11,
  tp5,
  tp12,
  tp10,
  tp3,
  tp8,
  tp17,
  tp6,
  tp19,
  tp14,
  tp21,
  tp2,
  tp23,
  tp1,
  tp22,
  tp15,
  tp20,
  tp13,
  tp18,
  tp7,
];

const Slides = images.map(photo => <Image src={photo} alt="The DubHacks Team" loading="eager"></Image>);

const EmblaCarousel: React.FC<PropType> = props => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ playOnInit: true, delay: 3000, stopOnInteraction: window.innerWidth > 767 }), // only stop on interaction if on desktop
  ]);

  const { onNextButtonClick } = usePrevNextButtons(emblaApi);

  const onButtonAutoplayClick = useCallback(
    (callback: () => void) => {
      const autoplay = emblaApi?.plugins()?.autoplay;
      if (!autoplay) return;

      const resetOrStop = autoplay.options.stopOnInteraction === false ? autoplay.reset : autoplay.stop;

      resetOrStop();
      callback();
    },
    [emblaApi],
  );

  return (
    <Embla onClick={() => onButtonAutoplayClick(onNextButtonClick)}>
      <EmblaViewport ref={emblaRef}>
        <EmblaContainer>
          {slides.map(index => (
            <EmblaSlide key={index}>
              <EmblaSlideNumber>
                <span>{Slides[index]}</span>
              </EmblaSlideNumber>
            </EmblaSlide>
          ))}
        </EmblaContainer>
      </EmblaViewport>
    </Embla>
  );
};

export const Slideshow: React.FC = () => {
  const OPTIONS: EmblaOptionsType = { loop: true };
  const SLIDE_COUNT = images.length;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  return <EmblaCarousel slides={SLIDES} options={OPTIONS} />;
};
