import React from 'react';
/*import { GoogleForm } from '../components/GoogleForm';*/
/*import { ApplyInfo } from '../components/ApplyInfo/ApplyInfo';*/
import { JoinUs } from '../components/JoinUsDefault/JoinUs';

const PageJoinBase: React.FC = props => {
  return (
    <div>
      <JoinUs />
      {/*<ApplyInfo />*/}
      {/*<GoogleForm />*/}
    </div>
  );
};

export default PageJoinBase;
