import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { INTERNAL_LINKS } from '../utils/links';
import { THEME_COLORS } from '../utils/theme';
import { ReactComponent as DH } from '../assets/DubHacksLogo.svg';
import { MobileNav } from './MobileNav';

const NavContainer = styled.div`
  width: 100%;
  top: 0;
  position: sticky;
  z-index: 999;
  background-color: white;
  min-height: 66.5px;
  @media (max-width: 767px) {
    padding-bottom: 15px;
  }
`;

const DeskTopNav = styled.div`
  user-select: none;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 4;
  @media (max-width: 767px) {
    display: none;
  }
`;

const DeskTopNavContainer = styled.div.attrs((props: { justify: string }) => props)`
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  display: flex;
  max-width: 1200px;
  justify-content: ${props => props.justify};
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  max-height: 60px;
  @media (max-width: 1450px) {
    margin-right: 120px;
  }
`;

const DeskTopNavMenu = styled.div`
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-around;
`;

const NavLinkDiv = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 7px;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  color: ${THEME_COLORS.brandPink};
  :hover {
    box-shadow: inset 100px 0 0 0 ${THEME_COLORS.brandPink};
    color: white;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  padding: 0;
  padding-bottom: 5px;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  border-bottom: ${props => (props.className === 'selected' ? '2.5px solid #f63690' : '')};
`;

export const Nav: React.FC = () => {
  const [currentLink, setCurrentLink] = useState<string>(INTERNAL_LINKS.HOME);

  useEffect(() => {
    const url = window.location.pathname;
    if (url === INTERNAL_LINKS.ABOUT) setCurrentLink(INTERNAL_LINKS.ABOUT);
    if (url === INTERNAL_LINKS.JOIN) setCurrentLink(INTERNAL_LINKS.JOIN);
  }, []);
  return (
    <>
      <NavContainer>
        {/* <Banner /> */}
        <MobileNav />
        <DeskTopNav>
          <DeskTopNavContainer justify={currentLink !== INTERNAL_LINKS.HOME ? 'space-between' : 'flex-end'}>
            {currentLink !== INTERNAL_LINKS.HOME && (
              <a href={INTERNAL_LINKS.HOME}>
                <DH style={{ width: '180px', marginLeft: 30, alignItems: 'center', display: 'flex' }} />
              </a>
            )}
            <DeskTopNavMenu>
              <NavLinkDiv>
                <StyledNavLink
                  onClick={() => setCurrentLink(INTERNAL_LINKS.HOME)}
                  className={currentLink === INTERNAL_LINKS.HOME ? 'selected' : ''}
                  to={INTERNAL_LINKS.HOME}
                >
                  Home
                </StyledNavLink>
              </NavLinkDiv>
              <NavLinkDiv>
                <StyledNavLink
                  onClick={() => setCurrentLink(INTERNAL_LINKS.ABOUT)}
                  className={currentLink === INTERNAL_LINKS.ABOUT ? 'selected' : ''}
                  to={INTERNAL_LINKS.ABOUT}
                >
                  About Us
                </StyledNavLink>
              </NavLinkDiv>
              <NavLinkDiv>
                <StyledNavLink
                  onClick={() => setCurrentLink(INTERNAL_LINKS.JOIN)}
                  className={currentLink === INTERNAL_LINKS.JOIN ? 'selected' : ''}
                  to={INTERNAL_LINKS.JOIN}
                >
                  Join Us
                </StyledNavLink>
              </NavLinkDiv>
            </DeskTopNavMenu>
          </DeskTopNavContainer>
        </DeskTopNav>
      </NavContainer>
    </>
  );
};
