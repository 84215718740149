import { EXTERNAL_LINKS } from '../../utils/links';
import {
  ApplyInfoSection,
  Callout,
  CalloutColumn,
  Text,
  Link,
  SubTitle,
  UnorderedList,
  ListItem,
  BigText,
} from './styles';
import { THEME_COLORS } from '../../utils/theme';
import { Slideshow } from './Slideshow';

export const JoinUs = () => {
  return (
    <ApplyInfoSection>
      <Slideshow />
      <BigText>Whether you're a student, professional, or company, there's a place for you at DubHacks.</BigText>
      <BigText style={{ color: THEME_COLORS.brandPink }}>Let's build tomorrow together.</BigText>
      <SubTitle>👪 Get Involved: Students</SubTitle>
      <Callout>
        <CalloutColumn>📅</CalloutColumn>
        <CalloutColumn>
          Applications to be a hacker at DubHacks 2025 will open September 2025 and be announced on all of our socials!
        </CalloutColumn>
      </Callout>
      <Text>
        DubHacks is run for students, by students. We run the largest technical events at UW, so get involved!
        <UnorderedList>
          <ListItem>
            <b>Join us at DubHacks 2025</b>, the biggest hackathon in the Pacific Northwest. We feed you, teach you, and
            give you tons of free swag and the chance to win big prizes. The hackathon will be early October 2025, and
            applications to attend open in September. All college students 18 and older from any university are
            eligible. Check out{' '}
            <Link href={EXTERNAL_LINKS.DH_24} target="_blank">
              last year
            </Link>
            !
          </ListItem>
          <ListItem>
            Join the Batch! <b>DubHacks NEXT</b> runs an intensive 16-week entrepreneurship and startup accelerator
            program for a small group of the most motivated and talented UW students. If you want to learn how to turn
            your idea into a product, apply to their Projects track. If you want to get involved in the entrepreneurship
            ecosystem at UW, apply as an EiR. DubHacks NEXT partners with the best VC firms in the PNW to make sure it
            remains the premier entrepreneurship space on campus.{' '}
            <Link href={EXTERNAL_LINKS.DH_NEXT} target="_blank">
              Learn more
            </Link>
            .
          </ListItem>
          <ListItem>
            <b>Join the team.</b> It takes a diverse set of skills to plan the largest hackathon in the Pacific
            Northwest: we need programmers, designers, managers, salespeople, artists, planners, and more. Applications
            to join the team that organizes our annual hackathon open in December and close in January each year.
          </ListItem>
        </UnorderedList>
        To get a taste of DubHacks, come and say hi at Admitted Students Day, the Engineering RSO Fair, Dawg Daze, the
        Allen School Welcome Day, or Red Square RSO Fair. We'll have merch and snacks!
      </Text>
      <SubTitle>🌟 Get Involved: Professionals</SubTitle>
      <Callout>
        <CalloutColumn>📅</CalloutColumn>
        <CalloutColumn>
          Applications to be a judge and/or mentor at DubHacks 2025 will open September 2025 and be announced on our{' '}
          <Link href={EXTERNAL_LINKS.DH_INSTAGRAM} target="_blank">
            Instagram
          </Link>{' '}
          and{' '}
          <Link href={EXTERNAL_LINKS.DH_LINKEDIN} target="_blank">
            LinkedIn
          </Link>
          !
        </CalloutColumn>
      </Callout>
      <Text>
        DubHacks works closely with volunteer industry professionals to run our events and connect our students with
        amazing mentors. Professionals can get involved in three ways:
        <UnorderedList>
          <ListItem>
            <b>Attend our annual October hackathon as a judge and/or mentor</b> to get an up-close look at hundreds of
            collegiate programming projects. This is our most popular initiative, and every year professionals from
            across the country join us for a weekend at DubHacks. Applications open September 2025.
          </ListItem>
          <ListItem>
            <b>Connect us with your company!</b> If you think your employer would like to sponsor DubHacks, connecting
            us with a member of your recruiting or university recruiting team is the first step to make that happen.
            Shoot us an email at{' '}
            <Link href={EXTERNAL_LINKS.DH_EMAIL} target="_blank">
              hello@dubhacks.co
            </Link>{' '}
            and we'll get things moving!
          </ListItem>
          <ListItem>
            <b>Speak at our events.</b> If you are think you or someone you know would be a standout speaker at DubHacks
            or DubHacks NEXT, email us at{' '}
            <Link href={EXTERNAL_LINKS.DH_EMAIL} target="_blank">
              hello@dubhacks.co
            </Link>{' '}
            so we can learn more!
          </ListItem>
        </UnorderedList>
      </Text>
      <SubTitle>💡 Get Involved: Companies</SubTitle>
      <Callout>
        <CalloutColumn>📅</CalloutColumn>
        <CalloutColumn>
          We know that our event date sometimes clashes with annual budget schedules, so we work with companies
          year-round to plan out sponsorships as far as two years in advance!
        </CalloutColumn>
      </Callout>
      <Text>
        Every year we partner with companies big and small to bring students and industry closer together. We've already
        begun partnering with companies for DubHacks 2025, but spots are still available.
      </Text>
      <Text>
        If your company is interested in learning more about our corporate sponsorship packages and process, reach out
        to{' '}
        <Link href={EXTERNAL_LINKS.DH_EMAIL} target="_blank">
          hello@dubhacks.co
        </Link>{' '}
        so we can get you a prospectus and schedule a meeting with our sponsorship team!
      </Text>
      <Text>
        For a taste of what sponsoring DubHacks is like, check out{' '}
        <Link href={EXTERNAL_LINKS.DH_24} target="_blank">
          DubHacks 2024
        </Link>
        !
      </Text>
    </ApplyInfoSection>
  );
};
