import styled from 'styled-components';

import { EXTERNAL_LINKS } from '../utils/links';

import { ReactComponent as DH21Logo } from '../assets/DH21Logo.svg';
import { ReactComponent as NextLogo } from '../assets/NextLogo.svg';

const OrgsContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-family: 'Work Sans', sans-serif;
  box-sizing: border-box;
  position: relative;
  z-index: 99;
  display: block;
  padding-top: 25px;
  padding-bottom: 40px;
  background-color: #fff;
`;

const OrgsAlignmentContainer = styled.div`
  display: flex;
  max-width: 80%;
  margin-top: 110px;
  margin-bottom: 110px;
  padding: 30px 20px;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-auto-flow: row;
    grid-template-areas: '.';
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
  }
  @media (max-width: 479px) {
    display: grid;
    padding-right: 9px;
    padding-left: 9px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 4px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
`;

const Org = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media (max-width: 767px) {
    width: 90%;
  }
`;

const OrgSvgContainer = styled.div`
  border: 0;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 10px;
`;

const OrgName = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #f63690;
  font-size: 3vw;
  font-weight: 700;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 2rem;
  }
  @media (max-width: 479px) {
    font-size: 7vw;
  }
`;

const OrgDates = styled.div`
  text-align: center;
  box-sizing: border-box;
  color: #434343;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
`;

const OrgDescription = styled.div`
  line-height: 20px;
  display: block;
  overflow: visible;
  max-width: 400px;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  color: #f63690;
  font-size: 18px;
  text-align: center;
  object-fit: fill;
  align-self: start;
  font-weight: 400;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    padding: 10px 0;
    font-size: 16px;
  }
`;

const OrgLearnMore = styled.a`
  -webkit-text-size-adjust: 100%;
  -webkit-box-direction: normal;
  text-align: center;
  box-sizing: border-box;
  padding: 9px 15px;
  border: 0;
  text-decoration: none;
  // cursor: pointer;
  display: flex;
  width: 190px;
  height: 50px;
  margin-top: 25px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #ff56a6;
  font-family: 'Catv 6x12 9', sans-serif;
  color: #fff;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
`;

const StyledDH21Logo = styled(DH21Logo)`
  height: 20vw;
  position: relative;
  @media (max-width: 767px) {
    height: 45vw;
  }
`;
const StyledNextLogo = styled(NextLogo)`
  height: 20vw;
  position: relative;
  @media (max-width: 767px) {
    height: 45vw;
    margin-top: 120px;
  }
`;

export const Orgs = () => {
  return (
    <>
      <OrgsContainer id="orgs">
        <OrgsAlignmentContainer>
          <Org>
            <OrgSvgContainer>
              <StyledDH21Logo />
            </OrgSvgContainer>
            <OrgName>DubHacks '24</OrgName>
            <OrgDescription>
              Our flagship collegiate hackathon hosted annually at the University of Washington
            </OrgDescription>
            <OrgDates>10.12.24 - 10.13.24</OrgDates>
            <OrgLearnMore href={EXTERNAL_LINKS.DH_24} target="_blank">
              LEARN MORE
            </OrgLearnMore>
          </Org>
          <Org>
            <OrgSvgContainer>
              <StyledNextLogo />
            </OrgSvgContainer>
            <OrgName>NEXT</OrgName>
            <OrgDescription>UW's premier startup accelerator and entrepreneurship community</OrgDescription>
            <OrgDates>Incubating: Batch 4</OrgDates>
            <OrgLearnMore href={EXTERNAL_LINKS.DH_NEXT} target="_blank">
              LEARN MORE
            </OrgLearnMore>
          </Org>
        </OrgsAlignmentContainer>
      </OrgsContainer>
    </>
  );
};
