import styled from 'styled-components';
import { THEME_COLORS } from '../../utils/theme';

export const ApplyInfoSection = styled.div`
  max-width: 779px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  color: ${THEME_COLORS.text};
  padding: 10px;
  margin: 0px auto 20px auto;
`;

export const TeamsGrid = styled.div`
  max-width: 739px;
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const Title = styled.div`
  line-height: 32px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  padding: 10px;
  color: ${THEME_COLORS.brandPink};
  font-size: 38px;
  text-align: center;
  object-fit: fill;
  align-self: start;
  font-weight: 800;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    margin-bottom: 0px;
    padding: 10px 0;
    line-height: 24px;
    font-size: 24px;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  line-height: 20px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  color: ${THEME_COLORS.brandPink};
  font-size: 24px;
  text-align: left;
  object-fit: fill;
  align-self: start;
  font-weight: 600;
  border-bottom: solid;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    padding: 10px 0;
    font-size: 18px;
  }
`;

export const Text = styled.div`
  width: 100%;
  line-height: 18px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  text-align: left;
  object-fit: fill;
  align-self: start;
  font-weight: 400;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const Callout = styled.div`
  display: flex;
  width: 100%;
  line-height: 18px;
  padding: 10px;
  margin: 10px auto;
  font-size: 16px;
  text-align: left;
  object-fit: fill;
  align-self: start;
  font-weight: 400;
  background: ${THEME_COLORS.brandPink}30;
  color: ${THEME_COLORS.text};
  border-radius: 10px;
  @media (max-width: 767px) {
  }
  @media (max-width: 479px) {
    font-size: 16px;
  }
  background: linear-gradient(
    60deg,
    ${THEME_COLORS.brandPink}30,
    ${THEME_COLORS.orangeSaturated}30,
    ${THEME_COLORS.brandPink}30,
    ${THEME_COLORS.blueSaturated}30
  );
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const Link = styled.a`
  color: ${THEME_COLORS.brandPink};
`;

export const CalloutColumn = styled.div`
  margin: 5px;
`;

export const OrderedList = styled.ol`
  margin-bottom: 5px;
  @media (max-width: 479px) {
    padding-left: 25px;
  }
`;

export const UnorderedList = styled.ul`
  margin-top: 10px;
  margin-bottom: 5px;
  @media (max-width: 479px) {
    padding-left: 22px;
  }
`;

export const BulletlessList = styled.ul`
  list-style-type: none; /* Remove bullets */
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 5px;
`;

export const ListItem = styled.li`
  margin: 3px;
`;

export const PermaCallout = styled.a`
  position: sticky;
  top: 73px;
  z-index: 5;
  width: 100%;
  text-decoration: none;
  line-height: 18px;
  padding: 15px;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  color: ${THEME_COLORS.brandWhite};
  box-shadow: 0px 0px 3px ${THEME_COLORS.text};
  border-radius: 10px;
  background: linear-gradient(
    60deg,
    ${THEME_COLORS.brandPink},
    ${THEME_COLORS.orangeSaturated},
    ${THEME_COLORS.brandPink},
    ${THEME_COLORS.blueSaturated}
  );
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const ApplicationSection = styled.div`
  width: 100%;
`;

export const ApplicationColumnContainer = styled.div`
  display: flex;
  width: 100%;
  line-height: 18px;
  font-size: 16px;
  justify-content: start;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  @media (max-width: 479px) {
    margin-top: 0;
    padding: 10px 0;
    font-size: 16px;
    flex-direction: column;
  }
`;

export const ApplicationColumnLeft = styled.div`
  width: max-content;
  min-width: max-content;
`;

export const ApplicationColumnRight = styled.div`
  width: 100%;
`;

export const FormIframe = styled.iframe`
  background: transparent;
  border: 1px solid #ccc;
  width: 100%;
  height: 75vh;
`;

export const BigText = styled.div`
  display: block;
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: ${THEME_COLORS.text};
  font-size: 32px;
  line-height: 44px;
  font-weight: 500;
  text-align: center;
  width: 100%;

  @media (max-width: 767px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 5px;
  }
`;
